import * as React from 'react'
import BasePage from '../components/basePage'
import { graphql, navigate } from 'gatsby'
import { Button, Container, Flex, Text } from '@chakra-ui/react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import GameItemBottomBar from '../components/gameItemBottomBar'
import Layout from '../layout'

const Game = ({ data }) => {
	const game = data?.markdownRemark?.frontmatter || {}
	const { id, logo, banner, desc, min, max, duration, rounds } = game
	const next = id === '9' ? '1' : `${parseInt(id) + 1}`

	return (
		<Layout title={`How to play - Game ${id}`}>
			<BasePage
				theme="dark"
				backBtn="/games"
				title="How to play"
				showMenuBtn={true}
				primaryBtn={
					<Flex flex="1" justifyContent="center">
						<Button
							variant="medium"
							onClick={() => navigate(`/game/game-${next}/`)}
						>
							Next Game
						</Button>
					</Flex>
				}
			>
				<Container variant="game">
					<Flex direction="column" justifyContent="center">
						<Flex justifyContent="center" mb="20px" h="112px">
							<GatsbyImage
								alt="Game Logo"
								image={getImage(
									logo?.childImageSharp?.gatsbyImageData
								)}
							/>
						</Flex>
						<Flex
							alignItems="center"
							justifyContent="center"
							h="260px"
						>
							<GatsbyImage
								alt="Game Banner"
								style={{ width: '100%' }}
								image={getImage(
									banner?.childImageSharp?.gatsbyImageData
								)}
							/>
						</Flex>
						<GameItemBottomBar
							min={min}
							max={max}
							duration={duration}
							rounds={rounds}
						/>
					</Flex>
				</Container>
				<Text variant="ml" color="white" textAlign="center" mt="20px">
					{desc}
				</Text>
			</BasePage>
		</Layout>
	)
}

export default Game

export const query = graphql`
	query ($name: String!) {
		markdownRemark(fields: { name: { eq: $name } }) {
			frontmatter {
				id
				min
				max
				duration
				desc
				rounds
				logo {
					childImageSharp {
						gatsbyImageData(layout: CONSTRAINED, height: 112)
					}
				}
				banner {
					childImageSharp {
						gatsbyImageData
					}
				}
			}
			html
		}
	}
`
